<template>
  <div class="container-web-app">
    <ArrowBack :path-name="toPageUrl($options.name)" />
    <br />
    <v-subheader class="headline font-weight-bold d-flex mb-6 align-center justify-center">
      <span
        :class="{
          'header--light': !isDarkModeEnabled,
          'header--dark': isDarkModeEnabled
        }"
      >
        YouTube Downloader
      </span>
      <!--suppress HtmlUnknownTarget -->
      <img alt="" class="ml-4" src="assets/img/svg/youtube-downloader.svg" />
    </v-subheader>

    <section>Download almost any video from YouTube, as a video and as an audio track!</section>
    <section class="my-6">
      <section>
        Because the extension is using Manifest V2, it can only work until
        <Url href="https://developer.chrome.com/docs/extensions/mv3/mv2-sunset" :dark-mode="isDarkModeEnabled"
          >January 2024</Url
        >.
      </section>
      <section>
        If you can, please help developing it for
        <Url href="https://developer.chrome.com/docs/extensions/mv3/intro/mv3-overview" :dark-mode="isDarkModeEnabled"
          >Manifest V3</Url
        >!
      </section>
    </section>

    <section class="mt-2">
      <v-btn :outlined="isDarkModeEnabled" href="https://linkshrink.ca/youtube-downloader">
        <v-icon left>{{ mdiDownload }}</v-icon>
        Download
      </v-btn>
    </section>

    <section class="mt-2 mb-6">
      <v-btn :outlined="isDarkModeEnabled" href="https://github.com/avi12/youtube-downloader"
        ><!--suppress HtmlUnknownTarget -->
        <v-img
          :class="{ 'product__logo--white': isDarkModeEnabled }"
          alt=""
          aria-hidden="true"
          class="mr-2"
          src="assets/img/svg/github.svg"
          width="18px"
        />
        Source code
      </v-btn>
    </section>

    <v-btn :outlined="isDarkModeEnabled" class="mb-1" @click="isShowScreenshots = !isShowScreenshots">
      <v-icon left>{{ mdiImageMultiple }}</v-icon>
      Screenshots
    </v-btn>
    <transition name="slide">
      <v-carousel v-if="isShowScreenshots" class="text-center" height="400">
        <v-carousel-item v-for="screenshot of screenshots" :key="screenshot">
          <img :src="`assets/img/screenshots/${product.pageUrl}/${screenshot}`" alt="Screenshot" class="screenshot" />
        </v-carousel-item>
      </v-carousel>
    </transition>

    <div class="mt-4 mb-2">Supported browsers</div>
    <img
      v-for="(logo, i) of logosSupportedBrowsers"
      :key="logo"
      :alt="browsersSupported[i].label"
      :class="{ 'mr-3': i < logosSupportedBrowsers.length - 1 }"
      :src="logo"
      class="logo-browser-supported"
      style="max-width: 40px"
    />

    <!--suppress HtmlDeprecatedAttribute -->
    <div v-for="video of videoInstallationTutorials" :key="video" class="video-container my-4">
      <!--suppress HtmlDeprecatedAttribute -->
      <iframe
        :src="video"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        frameborder="0"
        width="100%"
      ></iframe>
    </div>

    <v-btn :outlined="isDarkModeEnabled" @click="isShowFeatures = !isShowFeatures">
      <v-icon left>{{ mdiFormatListBulleted }}</v-icon>
      Features
    </v-btn>
    <transition name="slide">
      <v-list v-show="isShowFeatures" class="py-0" dense>
        <v-list-item class="mt-1">
          <v-list-item-content>
            <v-list-item-title>
              Download videos from <code>/watch</code> & <code>/playlist</code>, either as videos, audio tracks or
              audio-less videos
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-title>
            <v-list-item-content> View the download progress </v-list-item-content>
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-title>
            <v-list-item-content> All downloads are cancelable </v-list-item-content>
          </v-list-item-title>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              Via the pop-up page you can manage downloads, customize the file extensions and change the default video
              quality
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-subheader>Always keep in mind:</v-subheader>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              Videos with audio tracks will
              <i>always</i> be downloaded <i>one-by-one</i>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              Audio tracks or audio-less videos will
              <i>always</i> be downloaded <i>in parallel</i>, so be careful with your bandwidth!
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>
            <v-list-item-title>
              <Url :dark-mode="isDarkModeEnabled" href="https://github.com/avi12/youtube-downloader#features">
                The complete list
              </Url>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </transition>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import ArrowBack from "@/components/ArrowBack";
import Url from "@/components/Url";
import { mdiDownload, mdiFormatListBulleted, mdiImageMultiple } from "@mdi/js";

export default {
  name: "YoutubeDownloader",
  components: { Url, ArrowBack },
  data() {
    return {
      mdiDownload,
      mdiImageMultiple,
      mdiFormatListBulleted,
      isShowScreenshots: false,
      isShowFeatures: false,
      browsersSupported: [
        {
          browserName: "chrome",
          label: "Google Chrome",
          video: "https://www.youtube.com/embed/aMxenpDBvN4"
        },
        {
          browserName: "edge",
          label: "Microsoft Edge",
          video: "https://www.youtube.com/embed/lg2ejFFFEBI"
        },
        {
          browserName: "opera",
          label: "Opera",
          video: "https://www.youtube.com/embed/5NvG9kLatnk"
        },
        {
          browserName: "opera-gx",
          label: "Opera GX",
          video: "https://www.youtube.com/embed/PsgiGNXTNdw"
        }
      ]
    };
  },
  computed: {
    ...mapState(["products"]),
    ...mapGetters(["toPageUrl", "toPageUrl"]),
    isDarkModeEnabled() {
      return this.$store.getters.isDarkModeEnabled(this.$options.name);
    },
    product() {
      return this.products.find(({ pageUrl }) => pageUrl === this.toPageUrl(this.$options.name));
    },
    screenshots() {
      return this.product.screenshotsInfo.filenames;
    },
    logosSupportedBrowsers() {
      return this.browsersSupported.map(({ browserName }) => `assets/img/svg/${browserName}.svg`);
    },
    userBrowser() {
      if (navigator.userAgent.includes("OPR")) {
        return "opera";
      }
      return navigator.userAgent.includes("Edg") ? "edge" : "chrome";
    },
    videoInstallationTutorials() {
      if (this.userBrowser === "opera") {
        return this.browsersSupported
          .filter(({ browserName }) => browserName.includes("opera"))
          .map(({ video }) => video);
      }
      return [this.browsersSupported.find(({ browserName }) => browserName === this.userBrowser).video];
    }
  }
};
</script>

<style scoped>
/*noinspection CssUnusedSymbol*/
.v-list-item__title,
.v-list-item__content {
  white-space: normal;
}

/*noinspection CssUnusedSymbol*/
.header--light {
  color: red;
}

/*noinspection CssUnusedSymbol*/
.header--dark {
  color: rgb(255, 90, 90);
}

/*noinspection CssUnusedSymbol*/
.product__logo--white {
  filter: invert(100%);
}

.video-container {
  padding-top: calc(1080 / 1920 * 100%);
  position: relative;
}

iframe {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

/*noinspection CssUnusedSymbol*/
.slide-enter-active,
.slide-leave-active {
  transition: max-height 0.5s !important;
}

/*noinspection CssUnusedSymbol*/
.slide-enter-to,
.slide-leave {
  overflow: hidden;
  max-height: 400px;
}
</style>
